const FAQsItems = {
	data: () => ({
		faqItems: [
			{
				question: "What is Sachchai?",
				answer: "Something"
			},
			{
				question: "Is Sachchai about Christianity?",
				answer: "Something"
			},
			{
				question: "Does Sachchai force you to change your religion?",
				answer: "Something"
			},
			{
				question: "Sachchai, an illusion or a truth?",
				answer: "Something"
			},
			{
				question: "How can we reach you?",
				answer: "Something"
			}
		]
	})
}

export default FAQsItems
